import React from "react"
import Demo from "../components/demo"
import Layout from "../components/layout/t1/layout"

const demo = () => {
  return (
    <Layout>
      <Demo />
    </Layout>
  )
}

export default demo
