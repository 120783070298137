import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"
import Seo from "../seo"
import * as Yup from "yup"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { sendMail } from "../../services/mail"
import { Helmet } from "react-helmet"
import LottieAnimation from "../../animations/lottie"

const Demo = ({ _demo }) => {
  let frontmatter = {}
  const validationSchema = Yup.object().shape({
    FirstName: Yup.string()
      .max(100, "*Name must be less than 50 characters")
      .required("*This field is required"),
    LastName: Yup.string()
      .max(100, "*Name must be less than 50 characters")
      .required("*This field is required"),
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*This field is required"),
    company: Yup.string()
      .max(100, "*Name must be less than 100 characters")
      .required("*This field is required"),
    note: Yup.string().required("*This field is required"),
    submit: Yup.string(),
  })
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)

  try {
    const result = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(demo)/" }) {
          frontmatter {
            titleSection1
            descriptionSection2
            titleSection2
          }
        }
      }
    `)
    frontmatter = result.mdx.frontmatter
  } catch (error) {
    frontmatter = _demo
  }
  return (
    <div id="demo" className="demo" data-testid="Demo">
      <Seo
        title="Request Maxapps Maximo IBM Demo"
        description="See what MAXapps can do to transform your experience. Tell us a little about your situation and we’ll connect you with a MAXapps expert who would be happy to help."
      />
      <Helmet prepend>
        <meta property="og:title" content="MAXapps: Request A Demo" />
        <meta
          property="og:description"
          content="See what MAXapps can do to transform your experience. Tell us a little about your situation and we’ll connect you with a MAXapps expert who would be happy to help."
        />
        <meta
          property="og:image"
          content="https://maxapps-maximo.com/og/request-demo.jpg"
        />
        <meta
          property="og:url"
          content="https://maxapps-maximo.com/request-demo"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@maxapps" />
        <meta name="twitter:title" content="MAXapps: Request A Demo" />
        <meta
          name="twitter:description"
          content="See what MAXapps can do to transform your experience. Tell us a little about your situation and we’ll connect you with a MAXapps expert who would be happy to help."
        />
        <meta
          name="twitter:image"
          content="https://maxapps-maximo.com/og/request-demo.jpg"
        />
      </Helmet>
      <div className="DemoSection1 ">
        <div className="title-description">
          <div className="title">
            <h1>{frontmatter?.titleSection1}</h1>
          </div>
          <div className="description">{frontmatter?.descriptionSection2}</div>
        </div>
      </div>
      <div className=" container  col-md-10  col-lg-6 justify-content-center ">
        <div className="DemoSection2 ">
          <h2 className="text-center title">{frontmatter?.titleSection2}</h2>
          <div id="ContactForm" className="contact-form   ">
            <div className="container col-12 col-md-10   ">
              <Formik
                initialValues={{
                  FirstName: "",
                  LastName: "",
                  email: "",
                  company: "",
                  note: "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (
                  values,
                  { setSubmitting, resetForm, setStatus, setErrors }
                ) => {
                  setSubmitting(true)

                  const mailContent = {
                    name: `${values.FirstName} ${values.LastName}`,
                    email: values.email,
                    company: values.company,
                    note: values.note,
                  }

                  const res = await sendMail(mailContent, window.location.href)

                  if (res.success) {
                    resetForm({})
                    setStatus({ success: true })
                    setShowSuccessMsg(true)
                  } else {
                    setStatus({ success: false })
                    setErrors({ submit: res.message })
                  }

                  setTimeout(() => {
                    setShowSuccessMsg(false)
                  }, 3000)

                  setSubmitting(false)
                }}
              >
                {({ isSubmitting, touched, errors }) => (
                  <Form>
                    <div className="form-input">
                      <label htmlFor="Name" className="form-label">
                        First Name*
                      </label>
                      <Field
                        type="text"
                        name="FirstName"
                        className={`form-control ${
                          touched.name && errors.name ? "error" : null
                        }`}
                      />
                      <ErrorMessage
                        name="FirstName"
                        component="div"
                        className="error-message"
                      />
                    </div>{" "}
                    <div className="form-input">
                      <label htmlFor="Name" className="form-label">
                        Last Name*
                      </label>
                      <Field
                        type="text"
                        name="LastName"
                        className={`form-control ${
                          touched.name && errors.name ? "error" : null
                        }`}
                      />
                      <ErrorMessage
                        name="LastName"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="form-input">
                      <label htmlFor="Business Email" className="form-label">
                        Business Email*
                      </label>
                      <Field
                        type="email"
                        name="email"
                        className={`form-control ${
                          touched.email && errors.email ? "error" : null
                        }`}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="form-input">
                      <label htmlFor="Company Name" className="form-label">
                        Company Name*
                      </label>
                      <Field
                        type="text"
                        name="company"
                        className={`form-control ${
                          touched.company && errors.company ? "error" : null
                        }`}
                      />
                      <ErrorMessage
                        name="company"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="form-input">
                      <label htmlFor="Help" className="form-label">
                        What Can We Help You Achieve?*
                      </label>
                      <Field
                        type="text"
                        name="note"
                        component="textarea"
                        className={`form-control ${
                          touched.note && errors.note ? "error" : null
                        }`}
                      />
                      <ErrorMessage
                        name="note"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <button
                      type="submit"
                      name="submit"
                      disabled={isSubmitting}
                      className="form-button submit-button "
                    >
                      Submit
                      {!isSubmitting && (
                        <i className="bi bi-arrow-right-short "></i>
                      )}
                      {isSubmitting && (
                        <div className="login-loader">
                          <LottieAnimation height={25} width={25} />
                        </div>
                      )}
                    </button>
                    <ErrorMessage
                      name="submit"
                      component="div"
                      className="error-message"
                    />
                    {showSuccessMsg && (
                      <div className="success-msg">
                        <i className="bi bi-envelope-fill"></i>
                        <h6>
                          we have received your request, we will answer you as
                          soon as possible.
                        </h6>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Demo
